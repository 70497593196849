import { OmitKnown } from "@nna/core";
import { useTranslation } from "next-i18next";

import { Confirm, ConfirmProps } from "./Confirm";
import { ButtonVariants } from "../../../features/ui-form/components/Button/Button";

/** Props for {@link ConfirmDeletion} */
export type ConfirmDeletionProps = OmitKnown<ConfirmProps, "title"> &
	Pick<Partial<ConfirmProps>, "title">;
/** A confirm modal when deleting something */
export function ConfirmDeletion(props: ConfirmDeletionProps) {
	// Note: this should probably not be here;
	//	it is not a "box" as a `confirm` or `alert`, but ¯\_(ツ)_/¯ (keep simple)

	const { t } = useTranslation();

	const {
		btnConfirm,
		title = t("modal.box.confirm-deletion.title"),
		...confirmProps
	} = props;

	return (
		<Confirm
			{...confirmProps}
			btnConfirm={{
				label: t("common.action.delete"),
				variant: ButtonVariants.DANGER_DISCRETE,
				...btnConfirm,
			}}
			title={title}
		/>
	);
}
