import { useTranslation } from "next-i18next";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { ZodI18n } from "..";
import { FormikI18nHookParams } from "./formik-18n";

/**
 * Hook to generate a formik's `validationSchema` builder.
 * The schemas translate its errors.
 *
 * Note: prefer the `validate function`, formik removes empty string with the schema
 *
 * @returns a builder of "i18n-ed" formik's `validationSchema`s
 * @see useFormikI18nSchema when there is no need to recreate the schema between renders
 * @example
 * ```tsx
 * const schema = z.object({ a: z.string() });
 * function Component() {
 * 	const buildI18nSchema = useFormikI18nSchemaBuilder();
 * 	return <Formik validationSchema={buildI18nSchema(schema)}>{...}</Formik>;
 * }
 * ```
 */
export function useFormikI18nSchemaBuilder() {
	const { t } = useTranslation();

	return <T>(...[schema, params = {}]: FormikI18nHookParams<T>) =>
		toFormikValidationSchema(schema, {
			errorMap: ZodI18n.asErrorMap(t, params.errorMap),
		});
}

/**
 * Hook to generate a formik's `validationSchema`.
 * The schema translates its errors.
 *
 * Note: prefer the `validate function`, formik removes empty string with the schema
 *
 * @param params to generate the schema
 * @returns a formik's `validationSchema` that will translate its errors
 * @see useFormikI18nSchemaBuilder when it is needed to recreate a schema between renders
 * 	or to use multiple of them
 * @example
 * ```tsx
 * const schema = z.object({ a: z.string() });
 * function Component() {
 * 	const i18nSchema = useFormikI18nSchema(schema);
 * 	return <Formik validationSchema={i18nSchema}>{...}</Formik>;
 * }
 * ```
 */
export function useFormikI18nSchema<T>(...params: FormikI18nHookParams<T>) {
	const builder = useFormikI18nSchemaBuilder();
	return builder<T>(...params);
}
