import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { planDtoSchema } from "./plan.dto";

/** Validation schema for {@link PlanQueryResultsDto} */
export const planQueryResultsSchema = createQueryResultsSchema(planDtoSchema);

/** Results for {@link PlanDto plans} from a query */
export type PlanQueryResultsDto = z.infer<typeof planQueryResultsSchema>;
