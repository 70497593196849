import styled from "@emotion/styled";
import { Modal, modalClasses, ModalDialog } from "@mui/joy";

import { Layout } from "../../features/ui-layout";
import { textH3 } from "../../features/ui-layout/styles/textStyles";
import { theme } from "../../features/ui-layout/styles/theme";

/** Modal root wrapper (to inject backdrop style) */
export const Wrapper = styled(Modal)`
	& .${modalClasses.backdrop} {
		background: ${theme.vars.palette.blue.primary};
		opacity: 0.25;
	}
`;

/** Modal dialog container */
export const Dialog = styled(ModalDialog)`
	background: ${theme.vars.palette.white.primary};
	border: 0;
	border-radius: 0;
	box-shadow: none;
	gap: 1.5rem;
	max-width: 900px !important;
	padding: 24px;
	width: 900px !important; /* We only use a fixed width, per designs */
`;

/** Header for a modal */
export const Header = styled(Layout.Lined)`
	border-bottom: 1px solid ${theme.vars.palette.grey[40]};
	flex-wrap: wrap;
	gap: 16px;
	padding: 16px;
	position: sticky;
`;

/** The title inside a {@link Header} */
export const HeaderTitle = styled.h2`
	flex-basis: max-content;
	${textH3}
`;

/** The (button) actions part of a {@link Header} */
export const HeaderActions = styled(Layout.Lined)`
	flex: 1;
	gap: 16px;
	justify-content: flex-end;
	justify-self: flex-end;
`;

/** Content (main body) for a modal */
export const Content = styled.div`
	overflow: auto;
`;
