import { TFunction } from "next-i18next";
import * as z from "zod";

/**
 * Translates {@link z.ZodInvalidTypeIssue}
 *
 * @param t translate function
 * @param issue to translate
 * @returns translation
 */
export function invalidType(t: TFunction, issue: z.ZodInvalidTypeIssue) {
	const { expected, received } = issue;
	if (received === "undefined" || received === "null") {
		return t("errors.zod.required");
	}

	switch (expected) {
		case "string":
			return t("errors.zod.invalid-type.string");
		case "date":
			return t("errors.zod.invalid-type.date");
		case "bigint":
		case "float":
		case "integer":
		case "number":
			return t("errors.zod.invalid-type.number");
		case "boolean":
			return t("errors.zod.invalid-type.boolean");

		// Not managed (yet)
		case "array":
		case "function":
		case "nan":
		case "null":
		case "map":
		case "never":
		case "object":
		case "promise":
		case "undefined":
		case "unknown":
		case "void":
		case "set":
		case "symbol":
			return t("errors.zod.invalid-type._default");
	}
}
