import * as z from "zod";

import { planModelSchema } from "../plan.model";

/** Validation schema for {@link PlanUpdateDto} */
export const planUpdateDtoSchema = planModelSchema
	.pick({
		// TODO: FG-17
	})
	.strict()
	.partial();

/** DTO to update a plan */
export type PlanUpdateDto = z.infer<typeof planUpdateDtoSchema>;
