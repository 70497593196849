import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { planDtoSchema } from "./plan.dto";

/** Validation schema for {@link PlanQueryDto} */
export const planQueryDtoSchema = createQueryObjectSchema(planDtoSchema, {
	coerce: true,
	strict: true,
})
	.extend({
		$search: z
			.string()
			.optional()
			.describe(
				'When provided (Truthy), it adds a custom "search-on-texts" condition',
			),
	})
	.strict();

/** DTO used to filter plan */
export type PlanQueryDto = z.infer<typeof planQueryDtoSchema>;
