import { FormikConfig, FormikErrors } from "formik";
import { TFunction, useTranslation } from "next-i18next";
import * as z from "zod";

import { ZodI18n } from "..";
import { FormikI18nHookParams } from "./formik-18n";
import { insertPathIntoDict } from "./insertPathIntoDict";

/**
 * Format a {@link z.ZodError} to be used with Formik
 *
 * @param error to format
 * @returns recursive dict of errors
 */
export function formatErrorToFormikI18n<T = object>(
	error: z.ZodError,
): FormikErrors<T> {
	return error.issues.reduce(
		(current, { message, path }) =>
			insertPathIntoDict(current, path, message),
		{},
	);
}

interface ValidateFormikI18nBase<S extends boolean> {
	/** Did the validation succeed */
	success: S;
}

/** Result of {@link validateFormikI18n} in case of success */
export interface ValidateFormikI18nSuccess<T>
	extends ValidateFormikI18nBase<true> {
	/** The correct data */
	data: T;
}
/** Result of {@link validateFormikI18n} in case of error */
export interface ValidateFormikI18nError<T>
	extends ValidateFormikI18nBase<false> {
	/** The raw error from Zod */
	error: z.ZodError<T>;
	/** The formatted errors for Formik */
	errorDict: FormikErrors<T>;
}

/** Result of {@link validateFormikI18n}  */
export type ValidateFormikI18nResult<T> =
	| ValidateFormikI18nError<T>
	| ValidateFormikI18nSuccess<T>;

/**
 * Validates a form value for formik validation and translates the errors
 *
 * @param t to translate the errors
 * @param value to validate
 * @param options for the validation and translation
 * @returns the correct data on success, else a recursive dict of errors
 */
export async function validateFormikI18n<T>(
	t: TFunction,
	value: unknown,
	options: FormikI18nHookParams<T>,
): Promise<ValidateFormikI18nResult<T>> {
	const [schema, params = {}] = options;
	const result = await schema.safeParseAsync(value, {
		errorMap: ZodI18n.asErrorMap(t, params.errorMap),
	});

	if (result.success) {
		return { data: result.data, success: true };
	}
	return {
		error: result.error,
		errorDict: formatErrorToFormikI18n(result.error),
		success: false,
	};
}

/**
 * Hook to generate a formik's `validate` builder.
 * The validation translate its errors.
 *
 * @returns a builder of "i18n-ed" formik's `validate`s
 * @see useFormikI18nValidate when there is no need to recreate the schema between renders
 * @example
 * ```tsx
 * const schema = z.object({ a: z.string() });
 * function Component() {
 * 	const buildI18nValidateBuilder = useFormikI18nValidateBuilder();
 * 	return <Formik validate={buildI18nValidateBuilder(schema)}>{...}</Formik>;
 * }
 * ```
 */
export function useFormikI18nValidateBuilder() {
	// Why not the library `zod-formik-adapter` ?
	// -> It does not use return the correct format of the errors (dict)
	const { t } = useTranslation();

	return <T>(...params: FormikI18nHookParams<T>) =>
		(async (values: unknown) =>
			validateFormikI18n<T>(t, values, params).then(res =>
				res.success ? void 0 : res.errorDict,
			)) satisfies FormikConfig<never>["validate"];
}

/**
 * Hook to generate a formik's `validate`.
 * The validation translates its errors.
 *
 * @param params to generate the validate
 * @returns a formik's `validate` function that will translate its errors
 * @see useFormikI18nValidateBuilder when it is needed to recreate a schema between renders
 * 	or to use multiple of them
 * @example
 * ```tsx
 * const schema = z.object({ a: z.string() });
 * function Component() {
 * 	const i18nSchema = useFormikI18nValidate(schema);
 * 	return <Formik validationSchema={i18nSchema}>{...}</Formik>;
 * }
 * ```
 */
export function useFormikI18nValidate<T>(...params: FormikI18nHookParams<T>) {
	const builder = useFormikI18nValidateBuilder();
	return builder<T>(...params);
}
