import * as z from "zod";

import { userDtoSchema } from "../../user/dtos";
import { customerModelSchema } from "../customer.model";

/** Validation schema for {@link CustomerDto} */
export const customerDtoSchema = customerModelSchema.extend({
	counselor: z
		// The counselor is always returned
		// `lazy` not mandatory but to avoid circular ref
		.lazy(() => userDtoSchema)
		.describe("The counselor data of the customer"),
});
/** Base Dto for `customer` (without plans) */
export type CustomerDto = z.infer<typeof customerDtoSchema>;
