// TODO: move elsewhere

/** An element of th path */
export type PathElement = number | string;

/** Dict of data */
export interface Dict<T> {
	[p: PathElement]: Dict<T> | T;
}

/**
 * Insert into a dict a value from an array of path
 *
 * @param dict to insert into
 * @param path where to insert
 * @param value to insert
 * @returns dict with inserted data
 *
 * @example
 * insertPathIntoDict({a: {}}, ["a", "b"], 1);
 * // {a: {b:1}}
 */
export function insertPathIntoDict<T>(
	dict: Dict<T>,
	path: readonly PathElement[],
	value: T,
): T | typeof dict {
	if (path.length === 0) {
		return value;
	}

	const [root, ...rest] = path;
	return {
		...dict,
		[root]: insertPathIntoDict(dict[root] ?? {}, rest, value),
	};
}
