import styled from "@emotion/styled";
import { ModalProps } from "@mui/joy";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";

import {
	Button,
	ButtonProps,
	ButtonVariants,
} from "../../../features/ui-form/components/Button/Button";
import { ModalCloseCustom } from "../ModalCloseCustom";
import * as ModalLayout from "../layout";

const Content = styled(ModalLayout.Content)`
	display: flex;
	flex-direction: column;
	padding: 16px;
	padding-top: 0;
`;

/** Available props for the buttons inside {@link Confirm} */
export type ConfirmButtonProps = Pick<ButtonProps, "label" | "variant">;
/** Props for {@link Confirm} */
export interface ConfirmProps extends Pick<ModalProps, "open"> {
	/** Props for the cancel button */
	btnCancel?: ConfirmButtonProps;
	/** Props for the confirm button */
	btnConfirm?: ConfirmButtonProps;
	/** Additional description in the modal */
	description?: ReactNode;
	/**
	 * When the modal closes.
	 * The parameter is `true` when it is closed from the confirm button
	 */
	onClose: (confirmed: boolean) => void;
	/** Title of the modal */
	title: ReactNode;
}
/** A confirm modal (to replace the default `window.confirm`) */
export function Confirm(props: ConfirmProps) {
	const { t } = useTranslation();

	const {
		btnCancel = {},
		btnConfirm = {},
		description,
		onClose,
		title = "ba",
		...modalProps
	} = props;

	return (
		<ModalLayout.Wrapper {...modalProps} onClose={() => onClose(false)}>
			<ModalLayout.Dialog>
				<ModalLayout.Header>
					<ModalLayout.HeaderTitle data-testid="modal/boxes/confirm/title">
						{title}
					</ModalLayout.HeaderTitle>

					<ModalLayout.HeaderActions>
						<ModalCloseCustom>
							{({ closeModal }) => (
								<Button
									label={t("common.action.cancel")}
									{...btnCancel}
									data-testid="modal/boxes/confirm/btn-cancel"
									onClick={() => closeModal()}
									variant={ButtonVariants.SECONDARY}
								/>
							)}
						</ModalCloseCustom>

						<Button
							label={t("common.action.confirm")}
							{...btnConfirm}
							data-testid="modal/boxes/confirm/btn-confirm"
							onClick={() => onClose(true)}
						/>
					</ModalLayout.HeaderActions>
				</ModalLayout.Header>

				<Content>{description}</Content>
			</ModalLayout.Dialog>
		</ModalLayout.Wrapper>
	);
}
