import { useState } from "react";

import { ConfirmDeletion, ConfirmDeletionProps } from "./ConfirmDeletion";

/** Props for the {@link ConfirmDeletion} inside {@link useConfirmDeletion} */
export type useConfirmDeletionProps = Pick<
	ConfirmDeletionProps,
	"btnCancel" | "btnConfirm" | "description" | "title"
>;
/**
 * A hook (shortcut) to use when the user should confirm a deletion before.
 * The hook managed the open state
 *
 * @param whenConfirmed event called when the user confirms
 * @param confirmProps the additional props for {@link ConfirmDeletion}
 * @example
 * const deletionModal = useConfirmDeletion(() => console.log("confirmed"));
 * return <div>{deletionModal.modal}<button onClick={() => deletionModal.open()}>open<button></div>
 */
export function useConfirmDeletion(
	whenConfirmed: () => void,
	confirmProps: useConfirmDeletionProps = {},
) {
	// TODO: the same can (should) be done for regular `Dialog`
	const [open, setOpen] = useState(false);

	return {
		/** The modal component to set somewhere in a React Element */
		modal: (
			<ConfirmDeletion
				{...confirmProps}
				onClose={confirmed => {
					setOpen(false);
					if (confirmed) {
						whenConfirmed();
					}
				}}
				open={open}
			/>
		),
		/** To open the modal */
		open: () => setOpen(true),
	};
}
