import { ModalClose } from "@mui/joy";
import React, { useRef } from "react";

/** Props for {@link ModalCloseCustom} */
export interface ModalCloseCustomProps {
	/** The component to show, with a function to click on the internal {@link ModalClose} */
	children: React.FC<{ closeModal: () => void }>;
}
/**
 * Can use a customer element to have the same interaction as the {@link ModalClose}.
 *
 * The internal {@link ModalClose} is not displayed.
 *
 * @param props for the component
 * @example
 * <ModalCloseCustom>{({closeModal}) => <button onClick={closeModal} />}</ModalCloseCustom>
 */
export function ModalCloseCustom(props: ModalCloseCustomProps) {
	// This is only because `joy` does not export its internal context to close the modal

	const closeModalRef = useRef<HTMLButtonElement>(null);
	const { children: Children } = props;

	return (
		<>
			<ModalClose ref={closeModalRef} css={{ display: "none" }} />
			<Children closeModal={() => closeModalRef.current?.click()} />
		</>
	);
}
