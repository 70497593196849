import { Schemas } from "@nna/core";
import { TFunction } from "next-i18next";

import { invalidString } from "./invalid-string";
import { invalidType } from "./invalid-type";

export type Fallback = (issue: Schemas.Issue.Issue) => string;

/**
 * Translates an {@link Issue}
 *
 * @param t translate function
 * @param issue to translate
 * @param fallback fallback if no "more specific" translation is found
 * @returns translation
 */
export function translate(
	t: TFunction,
	issue: Schemas.Issue.Issue,
	fallback?: Fallback,
): string {
	return Schemas.Issue.visitWithFallback<string>(
		issue,
		fallback ?? (() => t("errors.zod._default")),
		{
			invalid_date: () => t("errors.zod.invalid-date"),
			invalid_enum_value: ({ options, received }) =>
				t("errors.zod.invalid-enum", {
					options: options.join(", "),
					received,
				}),
			invalid_literal: ({ expected, received }) =>
				t("errors.zod.invalid-literal", { expected, received }),
			invalid_string: issue => invalidString(t, issue),
			invalid_type: issue => invalidType(t, issue),
			too_big: ({ inclusive, maximum }) =>
				inclusive
					? t("errors.zod.lt", { maximum })
					: t("errors.zod.lte", { maximum }),
			too_small: ({ inclusive, minimum }) =>
				inclusive
					? t("errors.zod.gt", { minimum })
					: t("errors.zod.gte", { minimum }),
		},
	);
}
