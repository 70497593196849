import { OmitKnown } from "@nna/core";
import { useField } from "formik";

import { DotPath } from "../../../utils/types/DotPath";
import { UserSelect, UserSelectProps } from "../inputs/UserSelectInput";

/** Props for {@link UserSelectOneField} */
export interface UserSelectOneFieldProps<
	T extends object | string,
	DisableClearable extends boolean = false,
> extends OmitKnown<
		UserSelectProps<false, DisableClearable>,
		"multiple" | "onBlur" | "onChange" | "value"
	> {
	/** Name of the input */
	name: T extends object ? DotPath<T> : T;
}
/**
 * Wraps a {@link UserSelect} for a formik use
 * Currently usable for only one value
 */
export function UserSelectOneField<
	T extends object | string,
	DisableClearable extends boolean = false,
>(props: UserSelectOneFieldProps<T, DisableClearable>) {
	// TODO: a ModelSelectOne component (or hook) should be doable

	const { disableClearable, name, ...selectProps } = props;
	const [{ onBlur, onChange, value = null }] = useField<number | null>(name);

	return (
		<UserSelect<false, false>
			{...selectProps}
			disableClearable={disableClearable as never}
			multiple={false}
			name={name}
			onBlur={onBlur}
			onChange={(event, changed) => {
				const value = changed ? changed._id : changed;
				onChange({
					...event,
					target: { ...event.target, name, value },
				});
			}}
			value={value === null ? null : { _id: value }}
		/>
	);
}
