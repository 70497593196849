import { useTranslation } from "next-i18next";

import { ModalCloseCustom } from "./ModalCloseCustom";
import {
	Button,
	ButtonProps,
	ButtonVariants,
} from "../../features/ui-form/components/Button/Button";

/** Props for {@link ModalCloseButton} */
export type ModalCloseButtonProps = Pick<
	ButtonProps,
	"disabled" | "endDecorator" | "label" | "startDecorator" | "variant"
>;

/**
 * Can use a custom element to have the same interaction as the {@link ModalClose}.
 *
 * The internal {@link ModalClose} is not displayed.
 *
 * @param props for the component
 * @example
 * <ModalCloseCustom>{({closeModal}) => <button onClick={closeModal} />}</ModalCloseCustom>
 */
export function ModalCloseButton(props: ModalCloseButtonProps) {
	const { t } = useTranslation();

	const { label = t("common.action.cancel"), ...btnProps } = props;

	return (
		<ModalCloseCustom>
			{({ closeModal }) => (
				<Button
					variant={ButtonVariants.SECONDARY}
					{...btnProps}
					label={label}
					onClick={() => closeModal()}
					type="button"
				/>
			)}
		</ModalCloseCustom>
	);
}
