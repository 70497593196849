import { Schemas } from "@nna/core";
import { TFunction } from "next-i18next";
import * as z from "zod";

import { Fallback, translate } from "./translate";

/** Parameters for {@link asErrorMap} */
export interface AsErrorMapParams {
	/**
	 * To run before looking the default translations
	 * 	e.g. for a specific issue on a specific use-case
	 */
	before?: (
		issue: Schemas.Issue.Issue,
		ctx: z.ErrorMapCtx,
	) => Schemas.Issue.VisitResult<string>;
	/** Fallback when no "more specific" translation were found */
	fallback?: Fallback;
}

/**
 * Uses {@link translate} as a {@link z.ZodErrorMap} with "pre and post hooks"
 *
 * @param t translate function
 * @param params for creation of the ErrorMap
 * @returns a ZodErrorMap
 */
export function asErrorMap(
	t: TFunction,
	params: AsErrorMapParams = {},
): z.ZodErrorMap {
	const { before, fallback } = params;

	// default ZodErrorMap
	const defaultZEM: z.ZodErrorMap = issue => ({
		message: translate(t, issue, fallback),
	});

	if (!before) {
		return defaultZEM;
	}

	return (issue, ctx) => {
		const result = before(issue, ctx);
		return result.visited
			? { message: result.data }
			: defaultZEM(issue, ctx);
	};
}
