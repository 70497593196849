import { OmitKnown } from "@nna/core";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";

import {
	UserSelectOneField,
	UserSelectOneFieldProps,
} from "./UserSelectOneField";
import { userRepository } from "../../../services/api/repositories";
import { PeopleNameWithAvatar } from "../../people/components";
import { FormTable, Tooltips } from "../../ui-atoms/components";
import { ModelSelects } from "../../ui-form/components";

/** Props for {@link UserSelectOneCellField} */
export interface UserSelectOneCellFieldProps<
	T extends object | string,
	DisableClearable extends boolean = false,
> extends OmitKnown<FormTable.TableCellProps, "children" | "whenFocused">,
		Pick<UserSelectOneFieldProps<T, DisableClearable>, "name"> {
	/** Is the input disabled (and thus the focus mode) */
	disabled?: boolean;
	/** Text to show when empty */
	placeholder?: string;
	/** Override select props */
	select?: OmitKnown<UserSelectOneFieldProps<T, DisableClearable>, "name">;
}

/** Wraps {@link UserSelectOneField} to use as a "table-cell-input" */
export function UserSelectOneCellField<
	T extends object | string,
	DisableClearable extends boolean = false,
>(props: UserSelectOneCellFieldProps<T, DisableClearable>) {
	// TODO: a ModelSelectOneCellField component (or hook) should be doable

	const {
		disabled,
		name,
		placeholder,
		select: selectProps,
		...cellProps
	} = props;

	const [{ value = null }, { error }] = useField<number | null>(name);
	// Get the currently selected data
	const { data = null } = useQuery({
		...userRepository.findById({ userId: value || -1 }),
		enabled: !!value,
	});

	return (
		<FormTable.FocusableTableCell
			{...cellProps}
			whenFocused={
				<UserSelectOneField
					autoFocus
					{...selectProps}
					$applyCellStyles
					name={name}
					sx={ModelSelects.Styles.modelSelectCell}
				/>
			}
		>
			<FormTable.FormCellInnerWrapper error={!!error}>
				<FormTable.UnfocusedGenericValue
					disabled={disabled}
					placeholder={placeholder}
				>
					{data && <PeopleNameWithAvatar name={data.name} />}
				</FormTable.UnfocusedGenericValue>

				{error ? <Tooltips.Error title={error} /> : null}
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}
