import * as z from "zod";

import { planModelSchema } from "../plan.model";

/** Validation schema for {@link PlanCreateDto} */
export const planCreateDtoSchema = planModelSchema
	.pick({
		fkCounselor: true,
		fkCustomer: true,
		fkCustomer2: true,
		observationTime: true,
		year: true,
	})
	.strict();
/** DTO to create a plan */
export type PlanCreateDto = z.infer<typeof planCreateDtoSchema>;
