import { OmitKnown, Schemas } from "@nna/core";
import { Formik, FormikConfig, FormikValues } from "formik";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import * as z from "zod";

import { validateFormikI18n } from "../../../../services/formik";
import { FormikI18nSchemaParams } from "../../../../services/formik/formik-18n";

const validateHookDefault = () => Promise.resolve();

/** Props for {@link FormikWithI18nSchema} */
export interface FormikWithI18nSchemaProps<
	S extends z.ZodTypeAny,
	I extends FormikValues = Partial<z.input<S>>,
> extends OmitKnown<FormikConfig<I>, "onSubmit" | "validate"> {
	/** Override of the default "Formik's" submit with the correct parsed values */
	onSubmit?: FormikConfig<z.output<S>>["onSubmit"];
	/** The schema for the validation */
	schema: S;
	/** Parameters to use with the validation schema */
	schemaI18nParams?: FormikI18nSchemaParams;
	/** A post-hook validation to run after the schema */
	validateAfter?: FormikConfig<z.output<S>>["validate"];
	/** A pre-hook validation to run before the schema */
	validateBefore?: FormikConfig<I>["validate"];
}

/**
 * A override of the {@link Formik} component to be used with a zod validation schema (also translated)
 *
 * This also parses the the values before the `onSubmit`.
 * 	So, if the schema has some transformation (e.g. dates, phones,..),
 * 	they will be returned in the event
 *
 * @param props For the component
 */
export function FormikWithI18nSchema<
	S extends z.ZodTypeAny,
	I extends FormikValues = Partial<z.input<S>>,
>(props: FormikWithI18nSchemaProps<S, I>) {
	const {
		onSubmit,
		schema,
		schemaI18nParams,
		validateAfter = validateHookDefault,
		validateBefore = validateHookDefault,
		...formProps
	} = props;

	const { t } = useTranslation();

	const schemaForm = useMemo(
		// @ts-expect-error -- TODO: fix type error
		() => Schemas.objectForJson(schema) as never as S,
		[schema],
	);

	const validate = useCallback<NonNullable<FormikConfig<I>["validate"]>>(
		async values => {
			const before = await validateBefore(values);
			if (before) {
				return before;
			}

			const res = await validateFormikI18n<z.output<S>>(t, values, [
				schemaForm,
				schemaI18nParams,
			]);

			return res.success ? validateAfter(res.data) : res.errorDict;
		},
		[t, schemaForm, schemaI18nParams, validateAfter, validateBefore],
	);

	const handleSubmit: NonNullable<typeof onSubmit> = useMemo(
		() =>
			// The submit is only called after the validation (so after the schema)
			//	The send value is then parsed
			onSubmit
				? (values, helpers) =>
						onSubmit(
							schemaForm.parse(values) as z.output<S>,
							helpers,
						)
				: () => void 0,
		[schemaForm, onSubmit],
	);

	return (
		<Formik
			{...formProps}
			onSubmit={handleSubmit as never}
			validate={validate}
		/>
	);
}
